import React from 'react';

import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Stack,
  Typography,
} from '@mui/material';

interface IProps {
  progress: number;
  label?: string;
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const LoadingInsights: React.FC<IProps> = ({ progress, label }) => {
  return (
    <Stack
      alignItems="center"
      spacing={2}
      sx={{ paddingTop: '30px', paddingX: '10px' }}
    >
      {progress < 100 ? (
        <CircularProgressWithLabel value={progress} color="success" />
      ) : (
        <CircularProgress color="success" />
      )}
      <Typography variant="subtitle1" textAlign="center">
        {label ? label : 'Please wait.... Getting insights ready for you...'}
      </Typography>
    </Stack>
  );
};

export default LoadingInsights;
