import React from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';

import CustomModal from './CustomModal';

interface Props {
  open: boolean;
  headerOnClose?: VoidFunction;
  onClose: VoidFunction;
  onConfirm?: VoidFunction;
  onConfirmSecondBtn?: VoidFunction;
  title: string;
  confirmBtnText?: string;
  confirmSecondBtnText?: string;
  cancelBtnText?: string;
  renderAction?: React.ReactNode;
  children: React.ReactNode;
  buttonLoading?: boolean;
}

const ReusableConfirmationModal: React.FC<Props> = ({
  open,
  headerOnClose,
  onClose,
  onConfirm,
  onConfirmSecondBtn,
  title,
  confirmBtnText,
  confirmSecondBtnText,
  cancelBtnText,
  renderAction,
  children,
  buttonLoading,
}) => {
  return (
    <CustomModal
      title={title}
      open={open}
      handleClose={headerOnClose ? headerOnClose : onClose}
    >
      <Stack direction="column" spacing={2} margin="15px">
        {children}

        {renderAction ? (
          renderAction
        ) : (
          <Stack direction="row" spacing={1}>
            <LoadingButton
              loading={buttonLoading}
              onClick={onConfirm}
              variant="contained"
              style={{ padding: '5px 15px' }}
            >
              {confirmBtnText}
            </LoadingButton>
            {confirmSecondBtnText && (
              <LoadingButton
                loading={buttonLoading}
                onClick={onConfirmSecondBtn}
                variant="contained"
                style={{ padding: '5px 15px' }}
              >
                {confirmSecondBtnText}
              </LoadingButton>
            )}
            <LoadingButton
              loading={buttonLoading}
              onClick={onClose}
              variant="outlined"
              style={{ padding: '5px 15px' }}
            >
              {cancelBtnText}
            </LoadingButton>
          </Stack>
        )}
      </Stack>
    </CustomModal>
  );
};

export default ReusableConfirmationModal;
