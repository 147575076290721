import React, { useMemo, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Stack, Switch, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { getSessionStorage } from '../../../Authentication/Actions/authentication';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import UploadDocComponent from '../../../RiverusUI/Components/UploadDocComponent';
import {
  fetchChecklistData,
  fetchOptionalFields,
  getS3PresignedUrl,
  upload_file_in_s3_bucket,
} from '../../../Services/Draft';
import { draftStatus } from '../../State/DraftState';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  draftData: any;
  uploadContractData: (payload: any) => void;
}

const SparkMD5 = require('spark-md5');

const UploadDraftDialog: React.FC<Props> = ({
  open,
  onClose,
  draftData,
  uploadContractData,
}) => {
  const [uploadedFile, setUploadedFile] = useState<any>([]);
  const { control } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const getUserProfile = getSessionStorage('user_profile');
  const [isInternal, setIsInternal] = useState<boolean>(true);

  const onUploadProgress = React.useCallback(
    (progressEvent: any) => {
      const reader = new FileReader();
      let uploadProgress: any = { hexHash: 0 };
      const file = uploadedFile[0];
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          uploadProgress = {
            ...uploadProgress,
            [hexHash]: percentCompleted,
          };
        };
      }
    },
    [uploadedFile]
  );

  const handleIsInternal = React.useCallback(() => {
    setIsInternal(!isInternal);
  }, [isInternal]);

  const { data: getDraftCheckList } = useQuery({
    queryKey: ['draft_checkList', draftData?.draftID],
    queryFn: async () => await fetchChecklistData(draftData?.draftID),
    select: (response: any) => {
      const filterNotDeletedItem = response?.results?.filter(
        (item: any) => !item?.deleted_status
      );
      return filterNotDeletedItem;
    },

    enabled: !!draftData?.draftID,
  });

  const { data: optionalFields } = useQuery({
    queryKey: ['optional_fields', draftData?.draftID],
    queryFn: async () => await fetchOptionalFields(draftData?.draftID),
    select: (response: any) => {
      return response?.results;
    },
    enabled: !!draftData?.draftID,
  });

  const templateUserIds = useMemo(() => {
    return optionalFields
      ?.flatMap((field: any) => field?.field_data)
      ?.filter((fieldData: any) => fieldData?.assigned_type === 'template')
      ?.map((fieldData: any) => fieldData?.user);
  }, [optionalFields]);

  const checklistUserIds = useMemo(() => {
    return getDraftCheckList
      ?.filter((checklist: any) => checklist?.assigned_type === 'checklist')
      ?.map((checklist: any) => checklist?.user);
  }, [getDraftCheckList]);

  const filteredCollaborators = useMemo(() => {
    return draftData?.collaborators
      ?.filter((collaborator: any) => {
        const isInChecklist = checklistUserIds?.includes(collaborator?.id);
        const isInTemplate = templateUserIds?.includes(collaborator?.id);
        return isInChecklist || (isInChecklist && isInTemplate);
      })
      ?.map((collaborator: any) => collaborator?.id);
  }, [draftData, checklistUserIds, templateUserIds]);

  const uploadNewVersionDraft = (data: any) => {
    if (draftData) {
      const contract: any = {
        version: draftData?.version + 1,
        link: data.file_path,
        version_type: 'major',
        owner: draftData?.owner?.id ? draftData?.owner?.id : getUserProfile?.id,
        owners: draftData?.owners?.map((data: any) => data.id),
        creator: draftData?.creator?.id ? draftData?.creator?.id : '',
        signatories:
          draftData.status === draftStatus.SIGNATURE_ABORTED
            ? []
            : draftData?.signatories,
        collaborators: filteredCollaborators,
        approvers: [],
        requisition_id:
          draftData?.version === 0 ? draftData?.id : draftData?.requisition_id,
        draftID: draftData?.draftID,
        status: 'Internal Draft Created',
        contractName: draftData?.contractName,
        groups: draftData?.groups,
        createFrom: draftData?.createFrom,
        earlier_draft_link: draftData?.earlier_draft_link,
        executed_contract_link: draftData?.executed_contract_link,
        support_document_link: draftData?.support_document_link,
        contract_category: draftData?.contract_category?.id,
        contractType: draftData?.contractType
          ? draftData?.contractType?.id
          : null,
        logs: {
          action: `${
            isInternal ? 'Internal Draft Uploaded' : 'External Draft Uploaded'
          }`,
          action_on: [getUserProfile?.id],
        },
        reference_no: draftData?.reference_no,
      };
      contract['is_internal'] = isInternal;

      delete contract['id'];
      delete contract['created_by'];
      uploadContractData(contract);
    }
  };

  const { mutate: uploadDocInS3Bucket } = useMutation({
    mutationKey: ['upload_document_in_S3_bucket'],
    mutationFn: upload_file_in_s3_bucket,
    onSuccess: (response: any) => {
      uploadNewVersionDraft(response);
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to upload the document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: uploadDocument, isPending: loadingUploadDoc } = useMutation({
    mutationKey: ['upload_draft_document', uploadedFile],
    mutationFn: getS3PresignedUrl,
    onSuccess: (response) => {
      if (response?.data) {
        const file = uploadedFile?.[0];
        if (file) {
          const onHandleFileProgress = {
            onUploadProgress: (progressEvent: any) =>
              onUploadProgress(progressEvent),
          };
          uploadDocInS3Bucket({
            presignedPostData: response?.data?.presigned_url,
            file: file,
            onHandleFileProgress: onHandleFileProgress,
          });
        }
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to upload document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleUploadFile = () => {
    const file = uploadedFile?.[0];
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const hexHash = SparkMD5.hash(reader.result);
        const payload = {
          file_name: file?.name,
          file_hash: hexHash,
          file_size: file?.size,
          file_type: 'Contract_Draft',
          creation_type: 'custom',
        };
        uploadDocument(payload);
      };
    }
  };

  return (
    <CustomModal title="Upload draft" open={open} handleClose={onClose}>
      <Stack margin="20px 15px" spacing={2}>
        <UploadDocComponent
          label="Upload draft"
          control={control}
          name="counter_draft"
          allowedFileTypes={['.docx']}
          files={uploadedFile}
          setFiles={setUploadedFile}
        />

        <Stack direction="row" spacing={2}>
          <Switch
            checked={isInternal}
            onChange={handleIsInternal}
            name="toggleSwitch"
          />
          <Typography>
            {isInternal ? 'Internal Draft' : 'External Draft'}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <LoadingButton
            type="submit"
            loading={loadingUploadDoc}
            loadingPosition="start"
            variant="contained"
            onClick={() => handleUploadFile()}
          >
            Upload draft
          </LoadingButton>
          <Button variant="outlined" onClick={() => onClose()}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default UploadDraftDialog;
