import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Dialog, Typography } from '@mui/material';

import LoadingInsights from '../InsightsTab/LoadingInsights';

interface IProps {
  open: boolean;
  onClose: VoidFunction;
  isExternal?: boolean;
  isSuccess?: boolean;
}

const SignedConfirmation: React.FC<IProps> = ({
  open,
  onClose,
  isExternal,
  isSuccess = true,
}) => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10
      );
    }, 2000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      {isSuccess && progress > 30 ? (
        <Box
          sx={{
            p: 4,
            textAlign: 'center',
          }}
        >
          <Button
            disableRipple
            variant="contained"
            sx={{
              bgcolor: 'success.main',
              borderRadius: '50%',
              mb: 2,
              padding: '10px',
              '&:hover': {
                bgcolor: 'success.main',
              },
            }}
          >
            <CheckIcon sx={{ color: 'white', fontSize: 50 }} />
          </Button>
          <Typography id="modal-description" variant="body1" sx={{ mb: 4 }}>
            Thank you for signing the draft. Your signature has been placed
            successfully.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            {isExternal ? (
              <Typography id="modal-description" variant="body1" sx={{ mb: 4 }}>
                You can close the window.
              </Typography>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{ px: 4 }}
                onClick={onClose}
              >
                Go Back
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            p: 4,
            textAlign: 'center',
          }}
        >
          <LoadingInsights
            label="We're confirming that your signature has been placed correctly. Meanwhile, don't close/refresh this window."
            progress={progress}
          />
        </Box>
      )}
    </Dialog>
  );
};

export default SignedConfirmation;
